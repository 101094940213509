/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/dynamic-class.scss";
@import "./theme/mixins.scss";
@import "./theme/icons";
// common scss for ng-select
@import "src/theme/ng-select.scss";
@import "../node_modules/swiper/swiper-bundle.css";
@import "./responsive.scss";

@import "swiper/css/bundle";

* {
	font-family: "Montserrat", sans-serif;
}

.body-container {
	max-width: 1600px;
	width: 100%;
}

.text-left {
	text-align: left;
}

.right {
	float: right;
}

.left {
	float: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.btn-right {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.justify-end {
	justify-content: flex-end;
}

.justify-start {
	justify-content: flex-start;
}

.space-between {
	justify-content: space-between;
}

.space-around {
	justify-content: space-around;
}

.flex-center {
	display: flex;
	justify-content: center;
}

.flex {
	display: flex;
}

p {
	color: var(--ion-color-step-300);
}

.dark-text {
	color: var(--ion-color-step-100);
}

.text-link {
	text-decoration: underline;
	color: var(--ion-color-step-100);
	font-weight: 600;
}

.ion-page {
	justify-content: flex-start;
}

.password {
	position: relative;
	padding: 0;
	margin: 0;

	.registration-pass {
		margin: 0;
		padding-left: 30px;
	}

	.input-icon {
		position: absolute;
		top: 33px;
		right: 10px;
		z-index: 99999;
	}
}

.input-with-lock-icon {
	padding-right: 47px !important;
}

.input-toggle-icon {
	right: 19px !important;
	top: 34px !important;
	z-index: 99999 !important;
}

.dateField {
	position: relative;
	padding: 0;
	margin: 0;

	.input-icon {
		position: absolute;
		top: 12px;
		right: 10px;
		z-index: 2;
	}

	.calender-icon {
		position: absolute;
		top: 32px;
		right: 10px;
		z-index: 99999;
	}
}

.red-box {
	background: #e21a1a0d;
	border: 1px solid var(--ion-color-red);
	border-radius: 5px;
	color: var(--ion-color-red);
	display: flex;
	align-items: center;
	padding: 10px;
	text-align: center;
	line-height: 1.2rem;
}

.blue-box {
	background: #1432960d;
	border: 1px solid var(--ion-color-blue);
	border-radius: 5px;
	color: var(--ion-color-blue);
	display: flex;
	align-items: center;
	padding: 10px;
	text-transform: capitalize;
	text-align: center;
	line-height: 1.2rem;
}

.green-light-box {
	background: rgba(var(--ion-color-green-alpha), 0.1);
	border: 1px solid var(--ion-color-green);
	border-radius: 5px;
	color: var(--ion-color-green);
	display: flex;
	align-items: center;
	padding: 7px;
	line-height: 0.875rem;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;
	text-align: center;
	line-height: 1.2rem;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.orange-light-box {
	background: rgba(var(--ion-orange-color-alpha), 0.1);
	border: 1px solid var(--ion-orange-color);
	border-radius: 5px;
	color: var(--ion-orange-color);
	display: flex;
	align-items: center;
	padding: 7px;
	line-height: 0.875rem;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;
	text-align: center;
	line-height: 1.2rem;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.table-body,
.filter-items {
	label {
		cursor: pointer;
	}

	input[type="radio"] {
		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		/* Remove most all native input styles */
		appearance: none;
		/* For iOS < 15 */
		background-color: #ffffff;
		/* Not removed via appearance */
		margin: 0;
		outline: none;
		font: inherit;
		color: currentColor;
		width: 1.15em;
		height: 1.15em;
		border: 0.1rem solid #828282;
		border-radius: 50%;
		display: grid;
		place-content: center;
		cursor: pointer;
	}

	input[type="radio"]::before {
		content: "";
		width: 0.65em;
		height: 0.65em;
		border-radius: 50%;
		transform: scale(0);
		box-shadow: inset 1em 1em #ffffff;
		/* Windows High Contrast Mode */
		background-color: #ffffff;
		border: 0.4rem solid #ff6600;
	}

	input[type="radio"]:checked::before {
		transform: scale(1);
	}
}

.d-flex {
	display: flex;
}

.align-items-center {
	align-items: center;
}

.align-end {
	align-items: flex-end;
}

.justify-content-center {
	justify-content: center
}

.w-88 {
	width: 5.5rem;
}

.w-138 {
	width: 8.625rem;
}

.w-280 {
	width: 17.5rem;
}

.w-260 {
	width: 16.25rem;
}

.w-215 {
	width: 13.4375rem;
}

.w-200 {
	width: 12.5rem;
}

.w-169 {
	width: 10.5625rem;
}

.w-182 {
	width: 11.375rem;
}

.w-186 {
	width: 11.625rem;
}

.w-147 {
	width: 9.1875rem;
}

.w-156 {
	width: 9.75rem;
}

.w-152 {
	width: 9.5rem;
}

.w-115 {
	width: 7.1875rem;
}

.w-140 {
	width: 8.75rem;
}

.w-130 {
	width: 130px;
}

.width-100 {
	width: 100%;
}

.width-fill {
	width: -webkit-fill-available;
	width: fill-available;
}

.w-70 {
	width: 72px;
}

.height-100 {
	height: 100% !important;
}

.r-180 {
	transform: rotate(180deg);
}

.verified-btn {
	position: absolute;
	right: 0;
	background: var(--ion-color-green);
	border-radius: 4px;
	opacity: 1;
	height: 25px;
	letter-spacing: 0px;
	color: #ffffff;
	width: 68px;
	margin-right: 10px;

	@include respond-below(md) {
		font-size: 0.75rem;
	}

	@include respond-below(sm) {

		position: absolute;
		right: -5px;
		background: var(--ion-color-green);
		border-radius: 4px;
		opacity: 1 !important;
		height: 23px !important;
		letter-spacing: 0px;
		color: #ffffff;
		width: 21px !important;
		margin-right: 10px;



	}
}

.country-code {
	border-right: 2px solid var(--ion-border-color);
	width: 75px;
	padding-left: 10px;

	img {
		margin-bottom: 2px;
	}
}

.seperator {
	border: 1px solid var(--ion-border-color);
}

.native-input.sc-ion-input-md,
.native-input.sc-ion-input-ios {
	padding-left: 15px !important;
	padding-right: 60px !important;
	height: 100%;
}

.custom-input {

	.native-input.sc-ion-input-md,
	.native-input.sc-ion-input-ios {
		padding-right: 60px;
	}
}

.company-logo {
	cursor: pointer;
}

.no-pointer {
	cursor: default !important;
}

.mobile-input {

	.native-input.sc-ion-input-md,
	.native-input.sc-ion-input-ios {
		padding-right: 16px;
		padding-left: 10px !important;
	}
}

.address-container {
	.custom-input {

		.native-input.sc-ion-input-md,
		.native-input.sc-ion-input-ios {
			padding-right: 60px;
			cursor: pointer;
		}
	}
}

.sc-ion-input-md-h,
.sc-ion-input-ios-h {
	background: #ffffff;
	border: 1px solid var(--ion-border-color) !important;
	border-radius: 5px;
	opacity: 1;
	margin-top: 3px;
	height: 50px;
	border-radius: 5px;
	color: var(--ion-dark-text-color);
	font-size: 1rem;
	font-weight: 500;
	font-family: "Montserrat", sans-serif;


	@include respond-below(md) {
		height: 43px;
		font-size: 0.875rem;
	}
}

form {
	ion-label {
		font-size: 1rem;
		font-weight: 500;
		color: var(--ion-dark-text-color);
		text-transform: capitalize;
		font-family: "Montserrat", sans-serif;
	}

	ion-item {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid var(--ion-border-color);
		border-radius: 5px;
		opacity: 1;
		margin-top: 3px;

		ion-input {
			--background: var(--ion-text-color);
			border-radius: 5px;
			color: var(--ion-dark-text-color);
			font-size: 1rem;
			font-weight: 500;
			font-family: "Montserrat", sans-serif;
		}
	}
}

.ion-rating-stars {
	ion-icon {
		font-size: 1rem !important;
		opacity: 1 !important;
	}

}

@include respond-below (sm) {
	.ion-rating-stars {
		ion-icon {
			font-size: 0.625rem !important;
		}
	}
}



@include respond-between (sm,
	md) {
	.ion-rating-stars {
		ion-icon {
			font-size: 0.8rem !important;
		}
	}
}


ng-select {
	margin-top: 3px !important;
}

.native-input:disabled {
	opacity: 1;
	background: var(--ion-color-secondary-shade);
	color: var(--ion-color-medium); // #999999
	color: var(--ion-dark-text-color);
	opacity: 0.75;
}

.back-button {
	display: none;
}

.search-icon {
	position: absolute;
	right: 15px;
	cursor: pointer;

	@include respond-below(xs) {
		right: 0 !important;
	}
}

ion-spinner {
	--color: #ffffff;
	height: 20px;
}

.spinner-btn {
	--color: #ffffff;
	position: absolute;
	left: 0.5rem;
	top: 1rem;
}

.spinner-btn-qty {
	--color: #000000;
	position: absolute;
	right: 0.5rem;
	top: 1rem;
	z-index: 99;
}

.otp-spinner-btn {
	color: var(--ion-dark-text-color);
}

.input-icon {
	position: absolute;
	right: 7px;
	cursor: pointer;
	height: 20px;
	background: #ffffff;
	width: 25px;
	z-index: 2;
}

ion-select-popover {
	--offset-bottom: auto !important;
	--overflow: hidden;
	overflow: overlay;
	max-height: 200px;
}

.display-flex {
	display: flex;
}

.align-center {
	display: flex;
	align-items: center;
}

.align-baseline {
	display: flex;
	align-items: baseline;
}

.align-end {
	display: flex;
	align-items: end;
}

.align-item-center {
	align-items: center;
}

.display-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.orange-label {
	background: var(--ion-orange-color);
	color: #ffffff;
	border-radius: 5px;
	opacity: 1;
	height: 25px;
	width: fit-content;
	text-align: center;
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 7px;
	font-weight: 500;
}

.blue-label {
	background: var(--ion-color-blue);
	color: #ffffff;
	border-radius: 5px;
	opacity: 1;
	height: 25px;
	width: fit-content;
	text-align: center;
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 7px;
	font-weight: 500;
}


.sticky-column {
	position: sticky !important;
	right: -1px;
	background: #ffffff;
}

.sticky-column:after {
	box-shadow: inset -10px 0 10px -8px rgba(0, 0, 0, 0.1490196078);
	bottom: -1px;
	left: 0;
	transform: translate(-100%);
	position: absolute;
	width: 1.875rem;
	top: 0;
	pointer-events: none;
	content: "";
}

.sticky-column-2 {
	position: sticky !important;
	right: 0px;
	background: #ffffff;
}

.sticky-column-2:after {
	bottom: -1px;
	left: 0;
	transform: translate(-100%);
	position: absolute;
	width: 1.875rem;
	top: 0;
	pointer-events: none;
	content: "";
}

.capitalize {
	text-transform: capitalize !important;
}

.sticky-header {
	position: sticky !important;
	right: 0px;
	background: var(--ion-color-secondary-shade);

	&:after {
		box-shadow: inset -10px 0 10px -8px #00000026;
		bottom: -1px;
		left: 0;
		transform: translate(-100%);
		position: absolute;
		width: 1.875rem;
		top: 0;
		pointer-events: none;
		content: "";
	}
}

.sticky-header-2 {
	position: sticky !important;
	right: 0px;
	background: var(--ion-color-secondary-shade);

	&:after {
		bottom: -1px;
		left: 0;
		transform: translate(-100%);
		position: absolute;
		width: 1.875rem;
		top: 0;
		pointer-events: none;
		content: "";
	}
}

.success-modal {
	backdrop-filter: blur(10px);
	--width: 951px;
	--height: auto;
}

.isDesktop {
	display: block;
}

ion-toggle {
	vertical-align: middle;
	--background: var(--ion-color-medium-shade);
	--background-checked: var(--ion-color-green);
	--border-radius: 5px;
	--handle-background: #ffffff;
	--handle-background-checked: #ffffff;
	--handle-border-radius: 5px;
	--handle-width: 18px;
	--handle-height: 20px;
	--handle-max-height: calc(100% + 6px);
	--handle-spacing: 2.4px;
	--handle-transition: transform 160ms cubic-bezier(0.4, 0, 0.2, 1),
		background-color 160ms green;
	padding: 0px;
	width: 45px;
	height: 26px;
	contain: strict;
}

.pointer {
	cursor: pointer;
}

.label-box {
	padding: 4px 7px;
	border: 2px solid var(--ion-color-primary);
	color: #ffffff;
	background: var(--ion-orange-color);
	border-radius: 5px;
	opacity: 1;
	height: 100%;
	width: 100%;
	text-align: center;
	margin-top: 5px;
	display: flex;
	justify-content: center;
}

.arrow-right-class {
	transform: rotate(270deg);
	height: 5px;
	margin-left: 5px;
}

.rotate-180 {
	transform: rotate(180deg);
}

.no-record-found {
	font-size: 1.2rem;
	font-weight: 500;
	height: 150px;
	justify-content: center;
	align-items: center;
	display: flex;
	color: var(--ion-light-text-color);
}

.categoies-no-record {
	color: var(--ion-color-primary-contrast) !important;
}

.qty-div {
	border: 1px solid var(--ion-border-color);
	border-radius: 5px;
	opacity: 1;
	display: flex;
	align-items: center;
	padding: 0.5rem 0.625rem;
	margin-top: 12px;
	width: 100%;
	max-width: 100px;

	p {
		margin: 0;
		padding: 0;
		width: 100%;
	}

	span {
		float: right;
		width: inherit;
	}
}

.up-down {
	display: flex;
	flex-direction: column;

	ion-icon {
		height: 6px;
	}
}

.divider-line {
	height: 0.5px;
	width: 100%;
	background: var(--ion-divider);
}

/* Apply only for Mozilla Firefox */
@-moz-document url-prefix() {
	.divider-line {
		height: 1px;
		background: var(--ion-divider);
	}
}

.initial-pages-login {
	background-image: url(/assets/img/login-gif.gif);
	background-repeat: no-repeat;
	background-position: right;
	background-size: 50% 100%;
	height: 100vh;
	padding-left: 10%;
	padding-top: 0.3125rem;
	overflow: auto;
}

.bg-image-position-right {
	background-position: right;
}

.ionic5-star-rating {
	button {
		width: 1.0625rem !important;
		height: 0.9375rem !important;
		margin-right: 0.25rem !important;
	}
}

.custom-radio {
	--color-checked: var(--ion-orange-color);
}

.form-container {
	width: 62%;
	min-width: 440px;
	max-width: 600px;

	.form-inputs {
		padding-bottom: 0.75rem;
	}

	hr {
		border-bottom: 1px solid #d5dadc;
		height: 0;
		margin: 1.5rem 0;
	}

	.register-bottom-container {
		border-top: 1px solid #d5dadc;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.cancel {
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.125rem;
			letter-spacing: 0.4px;
			color: var(--ion-color-secondary-black);
			cursor: pointer;

			&:hover {
				font-weight: 600;
			}
		}

		.register-button {
			ion-button {
				--background: var(--ion-color-primary);
				font-weight: 600;
				font-size: 1rem;
				line-height: 1.125rem;
				letter-spacing: 0.4px;
				--box-shadow: none;
				border-radius: 5px;
				height: 46px;
				--padding-start: 2rem;
				--padding-end: 2rem;
			}
		}
	}
}

.more-info-tooltip {
	position: sticky;
	right: -27px;
	top: 25%;

	img {
		cursor: pointer;
	}
}

.more-info-tooltip .more-info-tooltiptext {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: 1.75rem;
	right: 0%;
	font-size: 0.875rem;
	width: fit-content;
	position: absolute;
	z-index: 99999;
	white-space: nowrap;
}

.more-info-tooltip .more-info-tooltiptext-left {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: -6px;
	right: 0;
	font-size: 0.875rem;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	z-index: 99999;
	white-space: nowrap;
	left: 25px;
}

.more-info-file-tooltip {
	width: fit-content !important;
}

ion-accordion.accordion-animated>[slot="header"] .ion-accordion-toggle-icon {
	width: 15px;
	height: 15px;
}

.skeleton-btn {
	height: 50px;
	border-radius: 5px;
}

.submit-btn {
	background: var(--ion-color-primary);
	height: 50px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: #fff;
	cursor: pointer;

	@include respond-below(md) {
		height: 40px;
		font-size: 0.875rem;
	}

	&:disabled {
		cursor: not-allowed;

		&:hover {
			background: var(--ion-color-primary);
			color: #ffffff;
		}
	}

	&:hover {
		background: var(--ion-color-blue);
		color: #ffffff;
	}
}

.white-btn {
	background: var(--ion-color-primary-contrast);
	border: 2px solid var(--ion-color-medium-shade);
	border-radius: 5px;
	height: 50px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: var(--ion-dark-text-color);

	&:disabled {
		cursor: not-allowed;

		&:hover {
			background: var(--ion-color-primary-contrast);
			color: var(--ion-dark-text-color);
		}
	}

	&:hover {
		background: var(--ion-color-blue);
		color: #ffffff;
	}
}

.clear-btn {
	background: var(--ion-color-primary-contrast);
	border: 2px solid var(--ion-color-medium-shade);
	border-radius: 5px;
	height: 50px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: var(--ion-dark-text-color);

	@include respond-below(md) {
		height: 40px;
		font-size: 0.875rem;
	}

	&:disabled {
		cursor: not-allowed;

		&:hover {
			background: var(--ion-color-primary-contrast);
			color: var(--ion-dark-text-color);
		}
	}

	&:hover {
		background: var(--ion-color-blue);
		color: #ffffff;
	}
}

.fiter-btn {
	border: 2px solid var(--ion-color-medium-shade);
	border-radius: 5px;
	height: 50px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: var(--ion-dark-text-color);
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;

	@include respond-below(md) {
		height: 40px;
		font-size: 0.875rem;
	}
}

.text-underline {
	text-decoration: underline;
}

.cursor-pointer {
	cursor: pointer;
}

.grey-btn {
	border: 1px solid var(--ion-dark-text-color);
	border-radius: 5px;
	height: 50px;
	padding-left: 30px;
	padding-right: 30px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: var(--ion-dark-text-color);
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;

	@include respond-below(md) {
		padding-left: 15px;
		padding-right: 15px;
		height: 40px;
		font-size: 0.875rem;
	}
}


.grey-search-btn {
	border: 1px solid var(--ion-dark-text-color);
	border-radius: 5px;
	height: 50px;
	padding-left: 30px;
	padding-right: 30px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: var(--ion-dark-text-color);
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;

	@include respond-below(md) {
		padding-left: 15px;
		padding-right: 15px;
		height: 40px;
		font-size: 0.875rem;
	}

	&:hover {
		background: var(--ion-color-blue);
		color: #ffffff;

		.grey-icon-btn {
			filter: invert(1) brightness(125%) sepia(0);
		}
	}
}

.filter-btn-grey {
	background: #ffffff;
	height: 50px;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
	border: 1px solid #293547;

	@include respond-below(md) {
		height: 40px;
		padding: 0 15px;
		font-size: 0.875rem;
	}

	.filter-text {
		color: var(--ion-dark-text-color);
	}

}

.grey-bg-btn {
	border: 1px solid var(--ion-color-secondary-shade);
	height: 50px;
	padding-left: 30px;
	padding-right: 30px;
	position: relative;
	font-weight: 600;
	font-size: 1rem;
	color: var(--ion-dark-text-color);
	background: var(--ion-color-secondary-shade);
	display: flex;
	justify-content: center;
	align-items: center;

	@include respond-below(md) {
		padding-left: 15px;
		padding-right: 15px;
		height: 40px;
		font-size: 0.875rem;
	}
}

.border-right-5 {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.border-left-5 {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.btn-container {
	background: var(--ion-color-secondary-shade);
	border-radius: 5px;
}

.blue-clear-btn {
	border: 2px solid var(--ion-color-blue);
	border-radius: 5px;
	height: 50px;
	padding-left: 30px;
	padding-right: 30px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: var(--ion-color-blue);
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;

	@include respond-below(md) {
		padding-left: 15px;
		padding-right: 15px;
		height: 40px;
		font-size: 0.875rem;
	}

	&:hover {
		background: var(--ion-color-blue);
		color: #ffffff;
	}
}

.filter-btn-blue {
	background: var(--ion-color-blue);
	height: 50px;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;

	@include respond-below(md) {
		padding: 0 15px;
		height: 40px;
		font-size: 0.875rem;
	}

	.filter-text {
		color: var(--ion-color-primary-contrast);
	}
}

.primary-btn {
	background: var(--ion-color-primary);
	height: 50px;
	padding-left: 50px;
	padding-right: 50px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:disabled {
		background: var(--ion-color-primary) !important;
		cursor: not-allowed;
	}

	@include respond-below(md) {
		padding-left: 30px;
		padding-right: 30px;
		height: 40px;
		font-size: 0.875rem;
	}

	&:hover {
		background: var(--ion-color-blue);
		color: #ffffff;
	}
}

.reject-btn {
	background: none;
	height: 50px;
	padding-left: 50px;
	padding-right: 50px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--ion-color-blue);
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
	}

	@include respond-below(md) {
		padding-left: 30px;
		padding-right: 30px;
		height: 35px;
		font-size: 0.875rem;
	}

	&:hover {
		background: var(--ion-color-blue);
		color: #ffffff;
	}
}

.hide-filter {
	background: var(--ion-color-primary-contrast);
	border: 1px solid var(--ion-dark-text-color);
	height: 50px;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;

	@include respond-below(md) {
		padding: 0 15px;
		height: 40px;
		font-size: 0.875rem;
	}

	.filter-text {
		color: var(--ion-dark-text-color);
	}
}

.paginator {
	.ngx-pagination {
		padding: 0;
		font-size: 1rem;
		font-weight: 500;
		display: flex;
		flex-wrap: wrap;

		li {
			color: var(--ion-dark-text-color);
			border: 1px solid var(--ion-border-color);
			border-radius: 5px;
			width: 50px;
			height: 50px;
			opacity: 1;
			margin-right: 1.25rem;

			@include respond-below(lg) {
				margin-bottom: 0.875rem;
			}

			@include respond-below(md) {
				width: 35px;
				height: 35px;
				margin-right: 0.875rem;
			}

			a {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		li:not(.current, .pagination-next, .pagination-previous) {
			background: var(--ion-color-primary-contrast);
		}

		a:hover,
		.ngx-pagination button:hover {
			border-radius: 4px;
		}

		.current {
			background: var(--ion-color-blue);
			color: var(--ion-color-primary-contrast);
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 1;
			border: none;
		}

		.pagination-next {
			margin-right: 0 !important;
		}

		.pagination-previous a:before,
		.pagination-previous.disabled:before {
			content: url("/assets/icon/arrow-left.svg");
			width: 7px;
			height: 14px;
			padding: 0.1875rem 0 0.1875rem 0.3125rem;
		}

		.pagination-next a:after,
		.pagination-next.disabled:after {
			content: url("/assets/icon/arrow-right.svg");
			width: 7px;
			height: 14px;
			padding: 0.1875rem 0.3125rem 0.1875rem 0;
		}

		.pagination-previous.disabled:before {
			opacity: 0.3 !important;
		}

		.pagination-next.disabled:after {
			opacity: 0.3 !important;
		}

		.pagination-previous a,
		.pagination-previous.disabled {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.pagination-next a,
		.pagination-next.disabled {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

ion-checkbox {
	--background-checked: var(--ion-color-green) !important;
	--border-color: var(--ion-border-color) !important;
	--border-color-checked: var(--ion-color-green) !important;
	--checkmark-color: #ffffff !important;
	--width: 20px;
	--height: 20px;
	height: 20px;
	width: 20px;
	--border-width: 1px;
	--border-radius: 3px;
	margin-top: 0.375rem;
	margin-bottom: 0;
	vertical-align: bottom;
}

.ion-select-overflow {
	background: #ffffff;
	border: 1px solid var(--ion-border-color);
	border-radius: 5px;
	opacity: 1;
	margin-top: 3px;
	height: 50px;
	border-radius: 5px;
	color: var(--ion-dark-text-color);
	font-size: 1rem;
	font-weight: 500;
	padding-left: 15px;
	padding-right: 10px;
}

code-input {
	--item-spacing: 0.75rem !important;
	opacity: 1;
	--item-height: 50px !important;
	--item-width: 50px;
	--item-border-radius: 0;
	background: var(--ion-color-secondary-shade) 0% 0% no-repeat padding-box;
	--item-border-radius: 5px;
	--item-spacing: 30px !important;

	input {
		background: #ffffff !important;
		width: 50px !important;
		height: 50px !important;
	}
}

.new-address {
	background: linear-gradient(90deg, #ff9a2a 0%, #ff6600 100%);
	border: none;
	color: #fff;
}

.blue-text {
	color: var(--ion-color-blue) !important;
}

// top-slider
.top-slider {
	.slides {
		margin-top: 0 !important;
		margin-bottom: 0 !important;

		.slide-content {
			.image-section {
				img {
					display: none !important;
				}
			}
		}
	}

	.swiper-container-horizontal>.swiper-pagination-bullets {
		left: 0;
		height: 1.25rem;
		top: 0;
		display: flex;

		.swiper-pagination-bullet {
			width: 100%;
			height: 0.375rem;
			display: inline-block;
			background: none;
			opacity: 0 !important;
			position: relative;
			margin: 0 0.5rem 0 0 !important;
			border-radius: 0 !important;
		}
	}
}

//swiper custom css
.recommended-container {
	.swiper {
		position: unset !important;
	}
}

.product-detail-slider {
	.swiper-button-prev {
		width: 50px;
		height: 50px;
		justify-content: center;
		align-items: center;
		display: flex;
		border: 1px solid var(--ion-color-secondary-shade);
		border-radius: 5px;
		background: white;
		left: 0px;

		@include respond-below(md) {
			width: 35px;
			height: 35px;
		}

		@media (max-width:476px) {
			width: 30px;
			height: 30px;
		}
	}

	.swiper-button-next {
		width: 50px;
		height: 50px;
		justify-content: center;
		align-items: center;
		display: flex;
		border: 1px solid var(--ion-color-secondary-shade);
		border-radius: 5px;
		background: white;
		right: 0px;

		@include respond-below(md) {
			width: 35px;
			height: 35px;
		}

		@media (max-width:476px) {
			width: 30px;
			height: 30px;
		}
	}

	.swiper-button-prev:hover,
	.swiper-button-next:hover {
		background: var(--ion-color-primary);
	}

	.categories,
	.brands {
		.swiper-button-next {
			top: 60%;

			@include respond-below(md) {
				top: 55%;
			}

			@include respond-between(ipadmin, ipadmax) {
				top: 50%;
			}

		}

		.swiper-button-prev {
			top: 60%;

			@include respond-below(md) {
				top: 55%;
			}

			@include respond-between(ipadmin, ipadmax) {
				top: 50%;
			}
		}
	}

	.swiper-pagination-bullet {
		height: 15px !important;
		width: 15px !important;
		background: transparent !important;
		border: 1.2px solid #ffffff !important;
		opacity: 0.6 !important;
		margin-right: 9px !important;
		margin-bottom: 10px !important;
	}

	.swiper-pagination-bullet-active {
		background: #ffffff !important;
		border: 5px solid var(--ion-orange-color) !important;
		height: 16px !important;
		width: 16px !important;
		opacity: 1 !important;
	}

	.swiper-pagination-fraction {
		display: none !important;
	}
}

.swiper-button-prev {
	width: 50px;
	height: 50px;
	justify-content: center;
	align-items: center;
	display: flex;
	border: 1px solid var(--ion-color-secondary-shade);
	border-radius: 5px;
	background: white;
	left: -70px;
}

.swiper-button-next {
	width: 50px;
	height: 50px;
	justify-content: center;
	align-items: center;
	display: flex;
	border: 1px solid var(--ion-color-secondary-shade);
	border-radius: 5px;
	background: white;
	right: -75px;
}

.swiper-button-next,
.swiper-button-prev {
	color: black;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
	background: var(--ion-color-primary);
	border: none;
	color: white !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
	font-size: 20px;
	justify-content: center;
	display: flex;
	font-weight: bold;
}

.categories,
.brands {
	.swiper-button-next {
		top: 60%;

		@include respond-below(md) {
			top: 55%;
		}

		@include respond-between(ipadmin, ipadmax) {
			top: 50%;
		}
	}

	.swiper-button-prev {
		top: 60%;

		@include respond-below(md) {
			top: 55%;
		}

		@include respond-between(ipadmin, ipadmax) {
			top: 50%;
		}
	}
}

.swiper-pagination-bullet {
	height: 15px !important;
	width: 15px !important;
	background: transparent !important;
	border: 1.2px solid #ffffff !important;
	opacity: 0.6 !important;
	margin-right: 9px !important;
	margin-bottom: 10px !important;
}

.swiper-pagination-bullet-active {
	background: #ffffff !important;
	border: 5px solid var(--ion-orange-color) !important;
	height: 16px !important;
	width: 16px !important;
	opacity: 1 !important;
}

.swiper-pagination-fraction {
	display: none !important;
}

.rotate-180 {
	transform: rotate(180deg) !important;
}

.rotate-90 {
	transform: rotate(90deg) !important;
}

.mobile-visibility-in-flex {
	display: none;
}

.isDesktop {
	display: block;
}

.isMobile {
	display: none;
}

.item-detail-icon {
	display: none !important;
}

.tab-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.radio_container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--ion-color-secondary-shade);
		height: 50px;
		border-radius: 5px;

		@include respond-below(md) {
			display: block;
			height: 100%;
		}
	}

	.radio_container {
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: var(--ion-color-secondary-shade);
		height: 50px;
		border-radius: 5px;
	}

	input[type="radio"] {
		appearance: none;
		display: none;
	}

	label {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--ion-color-secondary-shade);
		width: 50%;
		height: 50px;
		text-align: center;
		border-radius: 5px;
		overflow: hidden;
		transition: linear 0.3s;
		color: var(--ion-dark-text-color);
		font-weight: 500;
	}

	input[type="radio"]:checked+label {
		background-color: #ffffff;
		color: #f1f3f5;
		font-weight: 600;
		transition: 0.3s;
		border: 2px solid #143296;
		border-radius: 5px;
		opacity: 1;
		color: #143296;
	}
}

.progress-outer {
	width: 100%;
	border-radius: 20px;
	display: flex;
	align-items: center;

	.percent {
		width: 51px;
		height: 24px;
		background: #ffffff;
		border: 2px solid var(--ion-color-medium-shade);
		border-radius: 5px;
		display: flex;
		align-items: center;
		margin-left: -10px;
		text-align: center;
		justify-content: center;
	}
}

.progress-inner {
	white-space: nowrap;
	overflow: hidden;
	padding: 0px;
	border-radius: 20px;
	color: #fff;
	height: 10px;
	background-color: var(--ion-color-green);
}

.section-content {
	border: 1px solid var(--ion-border-color);
	border-radius: 5px;
	overflow-x: scroll;
	overflow-y: hidden;

	&::-webkit-scrollbar {
		width: 5px;
	}


	td {
		padding: 1.2rem 1rem 1rem 1.4rem;
		font-weight: 500;
		font-size: 1rem;
		line-height: 1.5rem;
		color: var(--ion-black-primary);
		align-items: center;

		.rights-data {
			max-width: 170px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.table-row {
		border-bottom: 1px solid var(--ion-border-color);
	}



	&::-webkit-scrollbar:horizontal {
		height: 0.3125rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--ion-divider);
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffffff;
		border-radius: 10px;
	}

	.listing-table {
		.table {
			width: 100%;

			thead {
				border-bottom: 1px solid var(--ion-border-color);
				border-radius: 5px 5px 0px 0px;
				background: var(--ion-color-secondary-shade);
				height: auto;
				vertical-align: baseline;
			}

			.column-heading {
				font-weight: 600;
				font-size: 1rem;
				line-height: 20px;
				color: var(--ion-dark-text-color);
				padding: 1.2rem 1rem 1rem 1.4rem;
			}

			.table-body {
				font: normal normal normal 16px/21px Noto Sans;
				letter-spacing: 0;
				color: var(--ion-black-primary);
				opacity: 1;

				.no-records {
					height: 50px;

					.no-data {
						color: var(--ion-color-medium) !important;
					}
				}

				td {
					padding: 1.2rem 1rem 1rem 1.4rem;
					font-weight: 500;
					font-size: 1rem;
					line-height: 1.5rem;
					color: var(--ion-black-primary);
					align-items: center;
					border-bottom: 1px solid var(--ion-border-color);

					.rights-data {
						max-width: 170px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						display: block;
					}
				}



				.table-row {
					border-bottom: 1px solid var(--ion-border-color);
				}

				.listing-table {

					.table {
						width: 100%;


						td {
							padding: 1.2rem 1rem 1rem 1.4rem;
							font-weight: 500;
							font-size: 1rem;
							line-height: 1.5rem;
							color: var(--ion-black-primary);

							.rights-data {
								max-width: 170px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}

						thead {
							border-bottom: 1px solid var(--ion-border-color);
							border-radius: 5px 5px 0px 0px;
							background: var(--ion-color-secondary-shade);
							height: 50px;
						}

						.column-heading {
							font-weight: 600;
							font-size: 1rem;
							line-height: 16px;
							color: var(--ion-dark-text-color);
							padding: 1.2rem 1rem 1rem 1.4rem;
						}

						.table-body {
							font: normal normal normal 16px/21px Noto Sans;
							letter-spacing: 0;
							color: var(--ion-black-primary);
							opacity: 1;

							.no-records {
								height: 50px;

								.no-data {
									color: var(--ion-color-medium) !important;
								}
							}

							td {
								padding: 1.2rem 1rem 1rem 1.4rem;
								font-weight: 500;
								font-size: 1rem;
								line-height: 1.5rem;
								color: var(--ion-black-primary);
								align-items: center;

								.rights-data {
									max-width: 170px;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								.payment-status {
									border: 1px solid var(--ion-orange-color);
									border-radius: 5px;
									background: var(--ion-orange-background-color);
									color: var(--ion-orange-color);
									max-width: fit-content;
									padding: 0.3125rem 0.625rem;
									font-size: 0.875rem;
								}

								.paid {
									border: 1px solid var(--ion-color-green);
									border-radius: 5px;
									background: var(--ion-green-background-color);
									color: var(--ion-color-green);
									max-width: fit-content;
									padding: 0.3125rem 0.625rem;
									font-size: 0.875rem;
								}
							}

							.table-row {
								border-bottom: 1px solid var(--ion-border-color);
							}



							.actionCol {
								display: flex;
								justify-content: space-between;
							}
						}
					}
				}
			}

			/* Remove the bottom border from the last row of the table body */
			.table-body tr:last-of-type td {
				border-bottom: none !important;
			}
		}
	}
}

table {
	border-collapse: separate
}

.success-top {
	--color: #ffffff;
	--height: 50px;
	--border-radius: 5px;
	--background: #22ab34;
	font-size: 0.9rem;

	@include respond-below(md) {
		font-size: 0.75rem;
	}

}

.success-middle {
	--color: #ffffff;
	--height: 50px;
	--border-radius: 5px;
	--background: #22ab34;
	font-size: 0.9rem;
	top: -130px;
	left: 170px;
	--max-width: 61%;

	@include respond-below(md) {
		font-size: 0.75rem;

	}
}

.warning-middle {
	--color: #ffffff;
	--height: 50px;
	--border-radius: 5px;
	--background: #f18922;
	font-size: 0.9rem;
	top: -130px;
	left: 175px;
	--max-width: 61%;

	@include respond-below(md) {
		font-size: 0.75rem;
		--max-width: 85%;
	}
}

.warning-top {
	--color: #ffffff;
	--height: 50px;
	--border-radius: 5px;
	--background: #f18922;
	--max-width: 50%;
	left: 20px;
	right: 10px;

	@include respond-below(md) {
		font-size: 0.8rem;
		--max-width: 100%;
		--height: fit-content;
		left: 0px;

	}
}

#addressModal {
	--height: auto;
	--width: 900px;
}

#filtermodal {
	--height: 100%;
	--width: 100%;
}

#requestQuoteModal {
	--width: auto;
	--height: auto;

	.section-content {
		.listing-table {
			.table {
				thead {
					height: 51px;
					position: absolute;
					z-index: 99;
					width: 899px;
				}

				.table-body {
					position: relative;
					top: 50px;
				}
			}
		}
	}
}

#datePopover,
#datePopover1 {
	&::part(content) {
		min-width: 269px;
	}
}

.custom-ellipse-text {
	max-width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
	vertical-align: text-bottom;
	position: relative;
	bottom: 1px;
	font-size: 0.875rem;
	font-weight: 500;
}


ion-datetime {
	height: 312px;
	color: #0e2266;
	font-weight: bold !important;
}

#thankYouModal {
	--width: 800px;
	--height: auto;
}

#escrow-modal {
	--width: 800px;
	--height: auto;
}

.terms-of-use {
	--width: 800px;
	--height: 500px;
}

.privacy-policy {
	--width: 800px;
	--height: 500px;
}

.error-middle {
	--color: #ffffff;
	--height: 50px;
	--border-radius: 5px;
	--background: #f02112;
	font-size: 0.9rem;
	top: -130px;
	left: 175px;
	--max-width: 61%;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.error-text {
	color: #f02112;
}

.error-top {
	--color: #ffffff;
	--min-height: 50px;
	--height: auto;
	--border-radius: 5px;
	--background: #f02112;
	font-size: 0.9rem;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.toast-link {
	color: #ffffff !important;

	@include respond-below(md) {
		font-size: 0.75rem;
	}

}

.no-data {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 20px;
	color: #c8c7c7;
	background: rgba(0, 0, 0, 0);
	opacity: 0.7;
}

.close-div {
	position: absolute;
	right: 0.3125rem;
	top: 0.7rem;
}

.order-summary {
	border: 1px solid var(--ion-border-color);
	border-radius: 5px;
	margin-top: 4.4rem;
	min-height: 250px;
}

.red-light-text {
	background: #e21a1a0c;
	border: 1px solid var(--ion-box-text-red-color);
	border-radius: 5px;
	opacity: 1;
	color: var(--ion-box-text-red-color);
	align-items: center;
	padding: 6px;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.common-accordian {
	ion-accordion-group {
		ion-accordion {
			ion-item {
				--ion-background-color: var(--ion-color-secondary-shade);
				--color: var(--ion-dark-text-color);
				font-weight: 500;
				font-size: 1rem;
			}
		}
	}

	ion-accordion.accordion-expanded>[slot="header"] {
		--ion-background-color: #ffffff;
		font-weight: 600;
		transition: 0.3s;
		border: 2px solid var(--ion-color-blue);
		border-radius: 5px;
		opacity: 1;
		color: var(--ion-color-blue);
	}

	.accordion-content {
		margin-top: 30px;
	}
}

.blue-light-text {
	background: #2935470c;
	border: 1px solid var(--ion-color-blu);
	border-radius: 5px;
	opacity: 1;
	color: var(--ion-color-blu);
	align-items: center;
	padding: 6px;
	height: 30px;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.orange-light-text {
	background: #f189220d;
	border: 1px solid var(--ion-orange-color);
	border-radius: 5px;
	opacity: 1;
	color: var(--ion-orange-color);
	align-items: center;
	padding: 6px;
	height: 30px;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.grey-light-text {
	background: #2935470c;
	border: 1px solid var(--ion-dark-text-color);
	border-radius: 5px;
	opacity: 1;
	color: var(--ion-dark-text-color);
	align-items: center;
	padding: 6px;
	height: 30px;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.red-laber-noBorder {
	background: #e21a1a0c;
	border-radius: 5px;
	opacity: 1;
	color: var(--ion-box-text-red-color);
	align-items: center;
	padding: 6px;
	height: 30px;
	font-size: 0.875rem;
	width: fit-content;

	@include respond-below(md) {
		font-size: 0.7rem;
	}
}

.grey-laber-noBorder {
	background: #2935470c;
	border-radius: 5px;
	opacity: 1;
	color: var(--ion-dark-text-color);
	align-items: center;
	padding: 6px;
	height: 30px;
	font-size: 0.875rem;
	width: fit-content;

	@include respond-below(md) {
		font-size: 0.7rem;
	}
}

.yellow-laber-noBorder {
	background: #c8f9910c;
	border-radius: 5px;
	opacity: 1;
	color: var(--ion-orange-color);
	align-items: center;
	padding: 6px;
	height: 30px;
	font-size: 0.875rem;
	width: fit-content;

	@include respond-below(md) {
		font-size: 0.7rem;
	}
}

.isDesktopTab {
	display: block;
}

.accordion-content {
	margin-top: 30px;
}

.pagination {
	margin-right: 10px;
	margin-left: 10px;
	width: 80px;
}

.from-date-div,
.to-date-div {
	display: flex;
	align-items: center;
	width: 100%;
}

.from-date-class,
.to-date-class {
	--min-height: 38px;

	ion-icon {
		margin: auto;
		cursor: pointer;
	}
}


.pagination-wrapper {
	display: flex;
	justify-content: space-between;
	padding: 0;
	height: 196px;

	@media (max-width:768px) {
		flex-direction: column;
		align-items: flex-start;
		justify-content: end;
	}

	.items {
		color: var(--ion-dark-text-color);
		font-weight: 500;
		font-size: 1rem;
		display: flex;
		align-items: center;
		margin-top: -100px;

		@include respond-below(md) {
			margin: 1rem 0;
		}


	}
}

.redirectlinks {
	text-decoration: underline;
	font-size: 1rem;
	letter-spacing: 0px;
	color: var(--ion-color-blue);
	font-weight: 600;
	cursor: pointer;
}

.redirectlinks-image {
	text-decoration: none !important;
}

.disable-address {
	.native-input {
		cursor: not-allowed !important;
	}
}

button {
	img {
		min-width: 20px !important;

		@include respond-below(md) {
			min-width: 15px !important;
		}
	}
}

.btn-img {
	min-width: 13px !important;

	@include respond-below(md) {
		min-width: 10px !important;
	}
}

.search-label {
	@include respond-between(ipadmin, ipadmax) {
		display: none;
	}
}

.field-label {
	font-size: 1rem;
	font-weight: 600;
	color: var(--ion-dark-text-color);
}



.h-20 {
	height: 20px;
}

.h-30 {
	height: 30px;
}

.h-40 {
	height: 40px;
}

.h-50 {
	height: 50px;
}

.border-none {
	border: none !important;
}

.border-all {
	border: 1px solid var(--ion-border-color);
}

.border-top {
	border-top: 1px solid var(--ion-border-color);
}


.flex-dir-col {
	flex-direction: column !important;
}

.tooltip {
	position: relative;
	vertical-align: middle;

	.invoice-icon {
		width: 25px;
	}

	img {
		min-width: 25px;
		min-height: 25px;
		max-height: 25px;
		max-width: 25px;
	}
}

/* Tooltip box */
.tooltip:before {
	content: attr(data-tooltip);
	position: absolute;
	width: auto;
	height: auto;
	background: var(--ion-color-blue);
	color: #fff;
	text-align: center;
	display: inline-block;
	justify-content: center;
	align-items: center;
	padding: 10px;
	line-height: 1.2;
	border-radius: 6px;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.6s;
	bottom: 160%;
	left: 50%;
	margin-left: -50%;
	font-size: 0.75em;
	visibility: hidden;
}

/* Tooltip arrow */
.tooltip:after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	opacity: 0;
	transition: opacity 0.6s;
	border-color: var(--ion-color-blue) transparent transparent transparent;
	visibility: hidden;
}

.tooltip:hover:before,
.tooltip:hover:after {
	opacity: 1;
	visibility: visible;
}

.flex-wrap {
	flex-wrap: wrap;
}

.grey-btn-hover {
	border: 1px solid var(--ion-dark-text-color);
	border-radius: 5px;
	height: 50px;
	padding-left: 30px;
	padding-right: 30px;
	position: relative;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1rem;
	color: var(--ion-dark-text-color);
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;

	@include respond-below(md) {
		padding-left: 15px;
		padding-right: 15px;
		height: 40px;
		font-size: 0.875rem;
	}

	&:hover {
		background: var(--ion-color-blue);
		color: #ffffff;
	}
}

.select-checkbox {
	width: 13px;
	height: 15px;
}

input[type="checkbox"] {
	accent-color: var(--ion-color-green);
}

.product-detail-slider {
	.swiper-slide {
		width: 100% !important;
		height: auto;
	}
}

.qty {
	position: relative;

	.qty-block {
		position: absolute;
		top: 9px;
		right: 10px;
		z-index: 2;
		display: grid;
	}

	p {
		margin: 0;
		padding: 0;
		width: 100%;
	}

	//   span {
	//     float: right;
	//     width: inherit;
	//   }
}

.br-0 {
	border-radius: 0;
}

.br-5 {
	border-radius: 5;
}

.h-8 {
	height: 8px;
}

.grey-light-box {
	background: rgba(var(--ion-text-color-alpha), 0.1);
	border: 1px solid var(--ion-text-color);
	border-radius: 5px;
	color: var(--ion-text-color);
	display: flex;
	align-items: center;
	padding: 7px;
	line-height: 0.875rem;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;
	text-align: center;
	line-height: 1.2rem;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.light-grey-text {
	color: var(--ion-color-secondary);
}

.text-color {
	color: var(--ion-text-color) !important;
}

.red-text {
	color: var(--ion-box-text-red-color);
}

.green-text {
	color: var(--ion-color-green);
	text-transform: capitalize;
}

.order-tracking-modal {
	backdrop-filter: blur(10px);
	--width: auto;
	--height: 100%;
	justify-content: flex-end;
}

.h-10 {
	height: 10px;
}

.red-light-box {
	background: rgba(var(--ion-box-text-red-color-alpha), 0.1);
	border: 1px solid var(--ion-box-text-red-color);
	border-radius: 5px;
	color: var(--ion-box-text-red-color);
	display: flex;
	align-items: center;
	padding: 7px;
	line-height: 0.875rem;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;
	text-align: center;
	line-height: 1.2rem;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.blue-light-box {
	background: rgba(var(--ion-color-blue-alpha), 0.1);
	border: 1px solid var(--ion-color-blue);
	border-radius: 5px;
	color: var(--ion-color-blue);
	display: flex;
	align-items: center;
	padding: 7px;
	line-height: 0.875rem;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;
	text-align: center;
	line-height: 1.2rem;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

@include respond-between(xl, xxl) {
	#requestQuoteModal {
		--width: 790px;
		--height: auto;
	}
}

.not-allowed {
	cursor: not-allowed !important;
}

.green-light-text {
	background: #22ab340c;
	border: 1px solid var(--ion-color-green);
	border-radius: 5px;
	opacity: 1;
	color: var(--ion-color-green);
	align-items: center;
	padding: 6px;
	font-size: 0.875rem;
	width: fit-content;
	text-transform: capitalize;

	@include respond-below(md) {
		font-size: 0.75rem;
	}
}

.ng-select .ng-placeholder {
	color: var(--ion-color-dark-tint) !important;
	opacity: 0.5 !important;
	font-weight: 500;
}

.ng-select .ng-clear-wrapper {
	display: none;
}

.address-row {
	.ng-select .ng-placeholder {
		color: var(--ion-color-dark-tint) !important;
		opacity: 0.5 !important;
		font-weight: 500;
	}

	.ng-select .ng-input input {
		background-color: #f2f2f2;
		border: 1px solid #ccc;
		padding: 5px;
		font-weight: 500;
	}

	.ng-select .ng-value-container .ng-value .ng-value-label {
		font-weight: 500;
	}
}

.pagination-container {
	display: flex;
	justify-content: flex-end;
}

.autoComplete {
	.mapOption {
		position: relative;

		ion-list {
			border: 1px solid var(--ion-border-color) !important;
			padding-bottom: 0px !important;
			border-radius: 5px;
			position: absolute;
			z-index: 99999;
			width: -webkit-fill-available;
			max-height: 240px;
			overflow: auto;

			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar:horizontal {
				height: 0.3125rem;
			}

			&::-webkit-scrollbar-thumb {
				background-color: var(--ion-divider);
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background-color: #ffffff;
				border-radius: 10px;
			}

			.items {
				border-bottom: 0.2px solid var(--ion-border-color) !important;
				padding: 10px;
				cursor: pointer;

				&:hover {
					background: var(--ion-color-light);
				}

				.autoMap {
					height: 15px;
				}
			}
		}
	}
}

.h-100 {
	height: 100px;
}

.width-70 {
	width: 70%;
}

.width-60 {
	width: 60%;
}

.width-80 {
	width: 80%;
}

.searchbar-search-icon.sc-ion-searchbar-md {
	right: 16px !important;
	width: 17px;
	height: 20px;
	left: unset;
	cursor: pointer !important;
	pointer-events: auto;

}

.searchbar-input.sc-ion-searchbar-md {
	padding-inline-start: 1rem;
	padding-inline-end: 2.3rem;
	font-size: 0.875rem;
}

.hide-search {
	.searchbar-search-icon.sc-ion-searchbar-md {
		display: none !important;
	}
}


.error-msg {
	color: var(--ion-color-danger) !important;

	@include respond-below(md) {
		font-size: 0.875rem;
	}
}

.delivery-modal {
	backdrop-filter: blur(10px);
	--width: 951px;
	--height: 550px;

	@include respond-below(lg) {
		--width: 600px;
	}

	@include respond-below(md) {
		--width: 300px;
		--height: 350px;
	}
}

.add_address_modal {
	backdrop-filter: blur(10px);
	--width: 50%;
	--height: auto;

	@include respond-below(lg) {
		--width: 600px;
	}

	@include respond-below(md) {
		--width: 90%;
		--height: 90%;
	}
}

.capitalize {
	text-transform: capitalize;
}

.text-overflow {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

button[disabled] {
	opacity: 0.3 !important;
}

.not-found-container {
	height: 250px;
	width: auto;
}

ion-spinner {
	--color: var(--ion-orange-color);
}

.displayHide {
	display: none;
}

.container-page {
	padding: 0.938rem 8rem 0.938rem 8rem;
}

@include respond-between(xl, xxl) {
	.container-page {
		padding: 0.938rem 3rem 0.938rem 3rem;
	}
}

@include respond-between(ipadmin, ipadmax) {
	.container-page {
		padding: 0.938rem 3rem 0.938rem 3rem;
	}
}

@include respond-below(md) {
	.container-page {
		padding: 0.938rem 1rem 0.938rem 1rem;
	}
}

.rating-stars {
	.ionic5-star-rating {
		button {
			margin-right: 0.331875rem !important;
		}
	}
}

.rating-stars-container {
	.ionic5-star-rating {
		button {
			margin-right: 1.5rem !important;
		}
	}

}

.rating-stars-profile {
	.ionic5-star-rating {
		button {
			margin-right: 0.15rem !important;
		}
	}
}

.absolute {
	position: absolute;
}

.header-md::after {
	background-image: none !important;
}

@include respond-above(md) {
	.toast-message {
		font-size: 0.75rem !important;
	}

	ion-content {
		--offset-bottom: auto !important;
		--overflow: hidden;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 0.3125rem;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--ion-divider);
			border-radius: 10px;
		}

		&::-webkit-scrollbar-track {
			background-color: #ffffff;
			border-radius: 10px;
		}
	}
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h {
	font-family: "Montserrat", sans-serif !important;
}

.cart-container,
.bank-form {

	.native-input.sc-ion-input-md,
	.native-input.sc-ion-input-ios {
		padding-left: 15px !important;
		padding-right: 6px !important;
		height: 100%;
	}
}

.ng-select-ellipse {
	display: inline-block;
	padding: 0.0625rem 0.3125rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 140px;
}

.filter-row-mobile {
	.dateField {
		.dateFieldInput {
			position: relative;
		}
	}
}

.swiper-slide {
	user-select: none;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
	cursor: not-allowed !important;
}

@media (max-width:390px) {

	.native-input.sc-ion-input-md,
	.native-input.sc-ion-input-ios {
		padding-left: 15px !important;
		padding-right: 40px !important;
		height: 100%;
		text-overflow: ellipsis;
	}
}

.cont-checkbox {
	ion-radio {
		--color: transparent;
		--background: transparent;
		--border-color: transparent;
		--inner-background: transparent;
		--outer-background: transparent;
		--color-checked: transparent;
		--border-color-checked: transparent;
	}
}

.label-cursor {
	cursor: pointer;
	user-select: none;
}

.label-select {
	user-select: none;
}

.inline-block {
	display: inline-block;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
	top: unset;
}

.pagination-justify-content-end {
	justify-content: end;
}

.pagination-content-start {
	flex-direction: row;
	justify-content: flex-start;
	height: auto;
}

.op-1 {
	opacity: 1 !important;
}

.attribute-value {
	width: auto;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}