// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: linear-gradient(90deg, #ff9a2a 0%, #ff6600 100%) 0% 0% no-repeat padding-box;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;
	--ion-divider: #d5dadc;

	/** secondary **/
	--ion-color-secondary: #656f7e;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #f2f2f2;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #828282;
	--ion-color-medium-tint: #9d9fa6;
	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
	--ion-light-text-color: #656f7e;
	--ion-dark-text-color: #293547;

	//custom
	--ion-color-green: #22ab34;
	--ion-color-green-alpha: 34, 171, 52;
	--ion-border-color: #b9c3d2;
	--ion-color-red: #ff4848;
	--ion-color-blue: #143296;
	--ion-color-blue-alpha: 20, 50, 150;
	--ion-orange-color: #ff6600;
	--ion-orange-color-alpha: 255, 102, 0;
	--ion-yellow-color: #ffbb1c;
	--ion-box-text-red-color: #e21a1a;
	--ion-box-text-red-color-alpha: 226, 26, 26;
	--ion-box-background-color: #1432960c;
	--ion-orange-background-color: #f189220d;
	--ion-green-background-color: #22ab340d;
	--ion-text-color: #293547;
	--ion-text-color-alpha: 41, 53, 71;
	--ion-gray-vehicle: #c0c0c0;

	--landing-grey: #515151;
	--landing-white: #F1F1F1;
	--landing-orange: #FF7C01;
	--landing-blue: #EDF2FF;
	--landing-blue-bg: #264796;
	--labding-border: #BDBDBD;
}