ion-icon {
    &[class*="custom-"] {
        mask-size: contain;
        mask-position: 50% 50%;
        mask-repeat: no-repeat;
        background: currentColor;
        width: 30px;
        height: 24px;
    }

    &[class*="custom-lock"] {
        mask-image: url('/assets/icon/lock.svg');
    }

    &[class*="open-lock"] {
        mask-image: url('/assets/icon/open-lock.svg');
    }

    &[class*="map"] {
        mask-image: url('/assets/icon/map.svg');
    }

    &[class*="upload-icon"] {
        mask-image: url('/assets/icon/camera.svg');
    }
}