@import "./theme/mixins.scss";

@include respond-between(xl, xxl) {
	.otp-container {
		ion-label {
			margin-bottom: 5px;
			padding-left: 1.75rem;
		}
	}

	code-input {
		--item-spacing: 0.75rem !important;
		opacity: 1;
		--item-height: 55px !important;
		--item-width: 40px;
		--item-border-radius: 0;
		background: var(--ion-color-secondary-shade) 0% 0% no-repeat padding-box;
		--item-border-radius: 5px;
		--item-spacing: 20px !important;

		input {
			background: #ffffff !important;
		}
	}

	.form-container {
		width: 100%;
	}

	.tab-container label {
		font-size: 0.9rem;
	}

	.initial-pages-login {
		background-image: url(/assets/img/login-gif.gif);
		background-repeat: no-repeat;
		background-position: right;
		background-size: 50% 100%;
		height: 100vh;
		padding-left: 10%;
		padding-top: 0.3125rem;
		overflow: auto;
	}
}

@include respond-between(ipadmin, ipadmax) {
	.autoComplete .complete ion-list {
		max-height: 155px;
		overflow: auto;

		.items {
			font-size: 0.75rem;
		}
	}

	#escrow-modal {
		--width: 600px;
		height: auto;
	}

	.isDesktopTab {
		display: block;
	}

	.swiper-button-prev {
		left: -60px;
	}

	.swiper-button-next {
		right: -60px;
	}

	.order-summary {
		margin-top: 1rem;
		min-height: 230px;
	}

	#requestQuoteModal {
		--width: 790px;
		--height: 870px;

		.section-content {
			.listing-table {
				.table {
					thead {
						width: 666px;
					}
				}
			}
		}
	}

	.login-container,
	.forgot-container,
	.form-container {
		width: 100% !important;
		max-width: 100% !important;
		min-width: 100% !important;
	}

	.company-logo {
		padding-top: 0rem;
		cursor: pointer;
	}

	.swiper-button-next {
		right: -60px;
	}

	.order-summary {
		margin-top: 1rem;
	}

	#requestQuoteModal {
		--width: 790px;
		--height: 870px;
	}

	.login-container,
	.forgot-container,
	.form-container {
		width: 100% !important;
		max-width: 100% !important;
		min-width: 100% !important;
	}

	.company-logo {
		padding-top: 0rem;
		cursor: pointer;
	}

	.swiper-button-prev {
		left: -60px;
	}

	.swiper-button-next {
		right: -60px;
	}

	.order-summary {
		margin-top: 1rem;
	}

	#requestQuoteModal {
		--width: 790px;
		--height: 870px;
	}

	.login-container,
	.forgot-container,
	.form-container {
		width: 100% !important;
		max-width: 100% !important;
		min-width: 100% !important;
	}

	.company-logo {
		padding-top: 0rem;
		cursor: pointer;
	}

	.swiper-button-next {
		right: -60px;
	}

	.order-summary {
		margin-top: 1rem;
	}

	#requestQuoteModal {
		--width: 790px;
		--height: 560px;

		.section-content {
			.listing-table {
				.table {
					thead {
						width: 688px;
					}
				}
			}
		}
	}

	.login-container,
	.forgot-container,
	.form-container {
		width: 100% !important;
		max-width: 100% !important;
		min-width: 100% !important;
	}

	.company-logo {
		padding-top: 0rem;
		cursor: pointer;
	}

	.success-modal {
		backdrop-filter: blur(10px);
		--width: 600px;
		--height: auto;
	}

	.isDesktop {
		display: none;
	}

	.isMobile {
		display: block;
	}

	.tab-container label {
		font-size: 0.85rem;
	}

	.form-container .submit-btn,
	.form-container .clear-btn {
		width: 135px;
	}

	.mobile-visibility-in-flex {
		display: flex;
	}


	code-input {
		--item-spacing: 0.75rem !important;
		opacity: 1;
		--item-height: 50px !important;
		--item-width: 50px;
		--item-border-radius: 0;
		background: var(--ion-color-secondary-shade) 0% 0% no-repeat padding-box;
		--item-border-radius: 5px;
		--item-spacing: 10px !important;

		input {
			background: #ffffff !important;
		}
	}

	.initial-pages-login {
		padding-left: 8%;
		background-image: none;
	}

	.form-container {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}

	.form-container .submit-btn,
	.form-container .clear-btn {
		width: 124px;
	}
}

@include respond-below(md) {
	ion-input input::placeholder {
		font-size: 0.8rem;
		word-wrap: normal;
	}

	.ng-select .ng-placeholder {
		font-size: 0.8rem;
	}

	header .bell-icon ion-badge {
		position: absolute;
		top: -8px;
		--background: var(--ion-color-primary);
		left: 12px;
		border-radius: 100%;
		--padding-start: 0;
		--padding-end: 0;
		--padding-top: 0;
		--padding-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.2rem;
		height: 1.2rem;
		font-size: 12px;
		font-weight: 500;
	}

	.autoComplete .mapOption ion-list {
		max-height: 145px;
		overflow: auto;

		.items {
			font-size: 0.75rem;
		}
	}

	.autoComplete .complete ion-list {
		max-height: 145px;
		overflow: auto;

		.items {
			font-size: 0.75rem;
		}
	}

	#escrow-modal {
		--width: 336px;
		height: auto;
	}

	.isDesktopTab {
		display: none;
	}

	.section-content .listing-table .table {
		width: 100%;
	}

	.close-div {
		top: 1.8rem;
	}

	.order-summary {
		margin-top: 1rem;
		min-height: 200px;
	}

	.white-btn {
		background: var(--ion-color-primary-contrast);
		border: 2px solid var(--ion-color-medium-shade);
		border-radius: 5px;
		height: 40px;
		position: relative;
		border-radius: 5px;
		font-weight: 600;
		font-size: 0.875rem;
		width: 145px;
		color: var(--ion-dark-text-color);

		&:hover {
			background: var(--ion-color-blue);
			color: #ffffff;
		}
	}

	.search-div ion-item {
		width: 100% !important;
	}

	.company-logo {
		padding-top: 0rem;
		cursor: pointer;
	}

	.swiper-button-prev {
		width: 31px;
		height: 31px;
		left: -30px;
	}

	.swiper-button-prev {
		width: 31px;
		height: 31px;
		left: -40px;
	}

	.swiper-button-next {
		width: 30px;
		height: 30px;
		right: -35px;
	}

	.swiper-button-next {
		width: 30px;
		height: 30px;
		right: -40px;
	}

	.success-modal {
		backdrop-filter: blur(10px);
		--width: 300px;
		--height: auto;
	}

	#requestQuoteModal {
		--width: 320px;
		--height: 540px;

		.section-content {
			.listing-table {
				.table {
					thead {
						height: unset;
						position: relative;
						z-index: 99;
						width: unset;
					}

					.table-body {
						position: relative;
						top: unset;
					}
				}
			}
		}
	}

	.isDesktop {
		display: none;
	}

	.isDesktop {
		display: none;
	}

	.isMobile {
		display: block;
	}

	.back-button {
		display: block;
	}

	.isDesktop {
		display: none;
	}

	.mobile-visibility-in-flex {
		display: flex;
	}

	.tab-container label {
		font-size: 0.85rem;
	}

	code-input {
		--item-spacing: 0.75rem !important;
		opacity: 1;
		--item-height: 50px !important;
		--item-width: 50px;
		--item-border-radius: 0;
		background: var(--ion-color-secondary-shade) 0% 0% no-repeat padding-box;
		--item-border-radius: 5px;
		--item-spacing: 10px !important;

		input {
			background: #ffffff !important;
		}
	}

	.initial-pages-login {
		background-image: none;
	}

	.form-container {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}

	.form-container .submit-btn,
	.form-container .clear-btn {
		width: 135px;
	}
}


@include respond-below(sm) {
	#escrow-modal {
		--width: 336px;
		height: auto;
	}

	ion-input input::placeholder {
		font-size: 0.8rem;
		word-wrap: normal;
	}

	.ng-select .ng-placeholder {
		font-size: 0.8rem;
	}

	.swiper-button-prev {
		width: 31px;
		height: 31px;
		left: -30px;
	}

	.swiper-button-prev:after,
	.swiper-button-next:after {
		font-size: 15px;
	}

	.swiper-button-next {
		width: 30px;
		height: 30px;
		right: -33px;
	}

	.swiper-button-prev:after,
	.swiper-button-next:after {
		font-size: 15px;
	}

	.swiper-button-next {
		width: 30px;
		height: 30px;
		right: -30px;
	}

	.isDesktop {
		display: none;
	}

	.isMobile {
		display: block;
	}

	.mobile-visibility-in-flex {
		display: flex;
	}

	.isDesktop {
		display: none;
	}

	.tab-container label {
		font-size: 0.85rem;
	}

	.isDesktop {
		display: none;
	}

	code-input {
		--item-spacing: 0.75rem !important;
		opacity: 1;
		--item-height: 50px !important;
		--item-width: 50px;
		--item-border-radius: 0;
		background: var(--ion-color-secondary-shade) 0% 0% no-repeat padding-box;
		--item-border-radius: 5px;
		--item-spacing: 10px !important;

		input {
			background: #ffffff !important;
		}
	}

	.initial-pages-login {
		background-image: none;
	}

	.form-container {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}

}

@media (min-width: 699px) and (max-width: 824px) {
	#requestQuoteModal {
		--width: 790px;

		.section-content {
			.listing-table {
				.table {
					thead {
						height: 51px;
						position: absolute;
						z-index: 99;
						width: 666px;
					}
				}
			}
		}
	}
}

@include respond-below(lg) {
	#thankYouModal {
		--width: 500px
	}

	#requestQuoteModal {
		--width: 690px;
	}
}

@include respond-between(xs, md) {
	#requestQuoteModal {
		--width: 450px;
	}
}



@include respond-below(xs) {
	#requestQuoteModal {
		--width: 300px;
	}
}

@media (min-width: 0px) and (max-width: 576px) {
	ion-header {
		background: #fff;
	}

	.filter-row-mobile {
		ion-col {
			margin-top: 7px !important;
		}

		.dateField {
			.dateFieldInput {
				margin-right: 0 !important;
			}
		}

		.search-column {
			margin-top: 20px;

			.ml-25 {
				margin-left: 0;
			}
		}

		.p-0,
		.pr-20,
		.pl-20 {
			padding: 0 !important;
		}

		.mt-5 {
			margin: 0 !important;
		}

		.mt-10 {
			margin-top: 10px;
		}

		.close-div {
			top: 0.4rem;
		}
	}
}

@media (min-width: 577px) and (max-width: 767px) {
	.filter-row-mobile {
		ion-col {
			padding-right: 16px !important;
			margin-top: 16px;

			.close-div {
				right: 16px;
			}
		}

		.pl-20 {
			padding-left: 0;
		}

		.close-div {
			top: 0.4rem;
		}

		.mt-10 {
			margin-top: 0px;
		}

		.mb-10 {
			margin-bottom: 0px;
		}
	}
}

@media (max-width: 1024px) {

	.privacy-policy-container,
	.about-us-container {
		padding: 0 16px !important;
	}
}

@media (min-width: 768px) {
	.filter-row-mobile {
		ion-col {
			padding-right: 16px !important;
			margin-top: 16px;

			.close-div {
				right: 16px;
			}
		}
	}
}

@include respond-between(sm, md) {

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}
}

@media (max-width:590px) {
	#thankYouModal {
		--width: 300px
	}
}

@media (max-width:320px) {
	#thankYouModal {
		--width: 200px
	}
}

@include respond-below(ipadmax) {
	.filter-button-modal-container {
		display: block;
		width: 100%;
		padding: 0 10px 0 10px;
		margin-bottom: 10px;

		.apply-filter-modal-button {
			width: 100%;
			margin-bottom: 10px;
			margin-top: 10px;

			button {
				background: var(--ion-color-blue);
				color: #ffffff;
			}
		}

		.clear-fliter-modal-button {
			width: 100%;
			margin: 0;
			margin-top: 10px;
		}

		.grey-btn {
			font-size: 1.2rem !important;
		}
	}

}

@include respond-below(sm) {
	.filter-button-modal-container {
		display: flex;
		width: 100%;
		padding: 0 10px 0 10px;
		margin-bottom: 10px;

		.apply-filter-modal-button {
			width: 50%;
		}

		.clear-fliter-modal-button {
			width: 50%;
			margin-left: 10px;
		}

		.grey-btn {
			font-size: 1rem !important;
		}
	}
}