$dimensions : (0:0px, 3:3px, 4:4px, 5:5px, 7:7px, 10:10px, 12:12px, 15:15px, 16:16px, 20:20px, 25:25px, 28:28px, 30:30px, 35:35px, 40:40px,
	50:50px,
	55:55px, 70:70px, 80:80px, 90:90px, 75:75px, 95:95px,
	60:60px, 100:100px, 200:200px, 120:120px, m5:-5px, m4:-4px, m1:-1px, m2:-2px, m7:-7px, m10:-10px, m20:-20px, m30: -30px, m50:-50px, m90:-90px, 6p:6%);

@each $key,
$value in $dimensions {

	@media only screen and (max-width: 991px) {
		.mob-m-#{$key} {
			margin: $value !important;
		}

		.mob-mv-#{$key} {
			margin: $value 0 !important;
		}

		.mob-mh-#{$key} {
			margin: 0 $value !important;
		}

		.mob-ml-#{$key} {
			margin-left: $value !important;
			margin-right: 0 !important;
		}

		.mob-mr-#{$key} {
			margin-right: $value !important;
			margin-left: 0 !important;
		}

		.mob-mt-#{$key} {
			margin-top: $value !important;
		}

		.mob-mb-#{$key} {
			margin-bottom: $value !important;
		}

		.mob-p-#{$key} {
			padding: $value !important;
		}

		.mob-pv-#{$key} {
			padding: $value 0 !important;
		}

		.mob-ph-#{$key} {
			padding: 0 $value !important;
		}

		.mob-pl-#{$key} {
			padding-left: $value !important;
			padding-right: 0 !important;
		}

		.mob-pr-#{$key} {
			padding-right: $value !important;
			padding-left: 0 !important;
		}

		.mob-pt-#{$key} {
			padding-top: $value !important;
		}

		.mob-pb-#{$key} {
			padding-bottom: $value !important;
		}
	}

	.m-#{$key} {
		margin: $value;
	}

	.mv-#{$key} {
		margin: $value 0;
	}

	.mh-#{$key} {
		margin: 0 $value;
	}

	.ml-#{$key} {
		margin-left: $value;
	}

	.mr-#{$key} {
		margin-right: $value;
	}

	.mt-#{$key} {
		margin-top: $value;
	}

	.mb-#{$key} {
		margin-bottom: $value;
	}

	.p-#{$key} {
		padding: $value;
	}

	.pv-#{$key} {
		padding: $value 0;
	}

	.ph-#{$key} {
		padding: 0 $value;
	}

	.pl-#{$key} {
		padding-left: $value;
	}

	.pr-#{$key} {
		padding-right: $value;
	}

	.pt-#{$key} {
		padding-top: $value;
	}

	.pb-#{$key} {
		padding-bottom: $value;
	}

	.br-#{$key} {
		border-radius: $value;
	}


}

.m-auto {
	margin: auto;
}

$fontWeight : (100:100, 200:200, 300:300, 400:400, 500:500, 600:600, 700:700, 800:800, 900:900, n:normal, b:bold);

@each $key,
$value in $fontWeight {
	.fw-#{$key} {
		font-weight: $value !important;
	}
}

$fontSize : (12: 0.75rem, 16:1rem, 17:1.0625rem, 18:1.125rem, 19:1.1875rem, 14:0.875rem, 15:0.9375rem, 20:1.25rem, 22:1.375rem, 25:1.5625rem, 28:1.75rem, 32:2rem, 40:2.5rem, 48:3rem);

@each $key,
$value in $fontSize {
	.fs-#{$key} {
		font-size: $value;
	}

	.lh-#{$key} {
		line-height: $value;
	}
}




$count : (34:34, 2:2, 125:125, 3500:3500);

@each $key,
$value in $count {

	@property --num {
		syntax: "<integer>";
		initial-value: 0;
		inherits: false;
	}

	.counter-#{$key} {
		animation: counter 5s infinite alternate ease-in-out;
		counter-reset: num var(--num);
		font: 800 40px system-ui;
	}

	.counter-#{$key}::after {
		content: counter(num);
	}

	@keyframes counter {
		from {
			--num: 0;
		}

		to {
			--num: #{$value};
		}
	}

}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 14px !important;
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width:320px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 13px !important;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 14px !important;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 16px !important;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 12px !important;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

	.ion-page,
	html,
	:host,
	ion-button {
		font-size: 14px !important;
	}
}